<template>
  <!-- Variant 1 -->
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-flex tw-flex-col tw-items-start tw-self-start tw-text-start md:tw-items-center md:tw-self-center md:tw-text-center lg:tw-mb-10"
    >
      <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
      <h2>{{ vars.titleText }}</h2>
    </div>
    <div class="tw-w-full !tw-text-white">
      <Swiper
        :modules="[SwiperPagination]"
        @slideChange="onSlideChange"
        @swiper="onSwiper"
        :pagination="false"
        :slides-per-view="1"
        :space-between="24"
        :center-insufficient-slides="true"
        effect="fade"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
          1536: {
            slidesPerView: 6,
          },
        }"
        class="tw-w-full"
      >
        <SwiperSlide v-for="(card, index) in cards" :key="index">
          <nuxt-link class="n-link" :to="card.card_link">
            <div
              class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500"
            >
              <LibraryImage :w-ratio="9" :h-ratio="16" :src="card.card_photo" style="border-radius: var(--rounded)" />
              <div
                class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-30"
                style="border-radius: var(--rounded)"
              ></div>
              <div
                class="card-icon-v1 tw-pointer-events-none tw-absolute tw-right-0 tw-top-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:-tw-translate-y-0 group-hover:tw-opacity-100"
              >
                <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="tw-w-6 lg:tw-w-8">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.375 21.375H4.125C3.71016 21.375 3.375 21.0398 3.375 20.625V3.375C3.375 2.96016 3.71016 2.625 4.125 2.625H12.5625C12.6656 2.625 12.75 2.70937 12.75 2.8125V4.125C12.75 4.22812 12.6656 4.3125 12.5625 4.3125H5.0625V19.6875H20.4375V12.1875C20.4375 12.0844 20.5219 12 20.625 12H21.9375C22.0406 12 22.125 12.0844 22.125 12.1875V20.625C22.125 21.0398 21.7898 21.375 21.375 21.375ZM18.8173 4.66713L17.5938 3.4437C17.4837 3.33354 17.5493 3.1437 17.704 3.12495L21.9087 2.63276C22.0282 2.6187 22.1313 2.71948 22.1173 2.84135L21.6251 7.04604C21.6063 7.20073 21.4165 7.26635 21.3063 7.1562L20.0782 5.92807L14.0735 11.9328C14.0009 12.0054 13.8813 12.0054 13.8087 11.9328L12.8149 10.939C12.7423 10.8664 12.7423 10.7468 12.8149 10.6742L18.8173 4.66713Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </nuxt-link>
        </SwiperSlide>
      </Swiper>
      <div>
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v4"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>

  <!-- Variant 2 -->
  <section
    v-if="variants.v2 === variant"
    class="n-section-tertiary tw-overflow-hidden"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-flex tw-flex-col tw-items-center tw-self-center tw-text-center lg:tw-mb-10">
      <h2>{{ vars.titleText }}</h2>
    </div>
    <div class="tw-w-full !tw-text-white">
      <Swiper
        :modules="[SwiperPagination]"
        @slideChange="onSlideChange"
        @swiper="onSwiper"
        :pagination="false"
        :center-insufficient-slides="true"
        :space-between="20"
        effect="fade"
        :loop="true"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
          1536: {
            slidesPerView: 7,
            spaceBetween: 40,
          },
        }"
        class="tw-w-full lg:tw-w-[115%] xl:tw-w-[125%]"
      >
        <SwiperSlide v-for="(card, index) in cards" :key="index">
          <nuxt-link class="n-link" :to="card.card_link">
            <div
              class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500"
            >
              <LibraryImage :w-ratio="9" :h-ratio="16" :src="card.card_photo" style="border-radius: var(--rounded)" />
              <div
                class="shine tw-absolute tw-inset-0 tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-30"
                style="border-radius: var(--rounded)"
              ></div>
              <div
                class="card-icon-v1 tw-pointer-events-none tw-absolute tw-right-0 tw-top-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:-tw-translate-y-0 group-hover:tw-opacity-100"
              >
                <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="tw-w-6 lg:tw-w-8">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.375 21.375H4.125C3.71016 21.375 3.375 21.0398 3.375 20.625V3.375C3.375 2.96016 3.71016 2.625 4.125 2.625H12.5625C12.6656 2.625 12.75 2.70937 12.75 2.8125V4.125C12.75 4.22812 12.6656 4.3125 12.5625 4.3125H5.0625V19.6875H20.4375V12.1875C20.4375 12.0844 20.5219 12 20.625 12H21.9375C22.0406 12 22.125 12.0844 22.125 12.1875V20.625C22.125 21.0398 21.7898 21.375 21.375 21.375ZM18.8173 4.66713L17.5938 3.4437C17.4837 3.33354 17.5493 3.1437 17.704 3.12495L21.9087 2.63276C22.0282 2.6187 22.1313 2.71948 22.1173 2.84135L21.6251 7.04604C21.6063 7.20073 21.4165 7.26635 21.3063 7.1562L20.0782 5.92807L14.0735 11.9328C14.0009 12.0054 13.8813 12.0054 13.8087 11.9328L12.8149 10.939C12.7423 10.8664 12.7423 10.7468 12.8149 10.6742L18.8173 4.66713Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </nuxt-link>
        </SwiperSlide>
      </Swiper>
      <div>
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v5"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';

export default defineNuxtComponent({
  components: { Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      selectedcard: null,
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      popupConfig: {
        isActive: false,
      },
      isVisible: false,

      groupedVariables: {
        cards: [
          {
            card_photo: '/images/teamimage.png',
            card_title: 'Tara Mockovici',
            card_link: '/#',
          },
          {
            card_photo: '/images/teamimage.png',
            card_title: 'Tara Mockovici',
            card_link: '/#',
          },
          {
            card_photo: '/images/teamimage.png',
            card_title: 'Tara Mockovici',
            card_link: '/#',
          },
          {
            card_photo: '/images/teamimage.png',
            card_title: 'Tara Mockovici',
            card_link: '/#',
          },
          {
            card_photo: '/images/teamimage.png',
            card_title: 'Tara Mockovici',
            card_link: '/#',
          },
          {
            card_photo: '/images/teamimage.png',
            card_title: 'Tara Mockovici',
            card_link: '/#',
          },
          {
            card_photo: '/images/teamimage.png',
            card_title: 'Tara Mockovici',
            card_link: '/#',
          },
          {
            card_photo: '/images/teamimage.png',
            card_title: 'Tara Mockovici',
            card_link: '/#',
          },
        ],
      },
    };
  },

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },

  methods: {
    toggleTeamcardDetailPopup(card = null, value = false) {
      if (card.popup_title) {
        this.selectedcard = card;
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (this.variant === 'v1') {
        if (!this.swiper) return;
        const isAtStart = this.swiper.isBeginning;
        const isAtEnd = this.swiper.isEnd;
        if (isAtStart) this.canPaginateBack = false;
        else this.canPaginateBack = true;
        if (isAtEnd) this.canPaginateNext = false;
        else this.canPaginateNext = true;
      } else if (this.variant === 'v2') {
        if (!this.swiper) return;
        this.canPaginateBack = true;
        this.canPaginateNext = true;
      }
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
@media (hover: none) {
  .no-hover .shine-effect {
    filter: none;
  }

  .no-hover .shine {
    opacity: 20%;
  }

  .no-hover .card-icon-v1 {
    opacity: 100%;
    pointer-events: auto;
    transform: translateY(0px);
  }
}
</style>
