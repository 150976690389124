<template>
  <!-- Variant 1 -->
  <section
    v-if="variant === variants.v1"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <LibraryPopupsVideoPopup :videoUrl="currentVideoUrl" v-model:is-visible="isVisible" />

    <div class="tw-relative tw-flex tw-w-full tw-flex-col tw-items-center lg:tw-max-w-none">
      <div class="tw-self-start tw-text-start md:tw-self-center md:tw-text-center">
        <small v-if="vars.altTitleText">{{ vars.altTitleText }}</small>
        <h2 class="tw-mb-4 lg:tw-mb-12">{{ vars.titleText }}</h2>
      </div>
      <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="800">
        <Swiper
          :modules="[SwiperPagination]"
          @slideChange="onSlideChange"
          :pagination="false"
          :space-between="7"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 2,
            },
          }"
          @swiper="onSwiper"
          class="tw-w-full"
        >
          <SwiperSlide
            v-for="(review, index) in reviews"
            :key="`singletestimonials-review-${index}`"
            class="!tw-h-auto tw-px-2"
          >
            <div
              class="tw-flex tw-h-full tw-w-full tw-flex-col tw-p-8 tw-px-0 tw-duration-300 hover:tw-scale-[0.99] md:tw-px-8"
              style="border-radius: var(--rounded)"
            >
              <div class="lg:tw-row tw-flex tw-h-full tw-flex-col tw-items-center tw-gap-5 tw-text-start">
                <LibraryImage
                  v-if="review.image && !review.video"
                  :src="review.image"
                  :alt="vars.titleText"
                  class="tw-w-full"
                  :h-ratio="4.5"
                  :w-ratio="7"
                  style="border-radius: var(--rounded-sm)"
                />
                <div
                  v-if="review.image && review.video"
                  class="tw-relative tw-w-full"
                  @click="(isVisible = true), (currentVideoUrl = review.video)"
                >
                  <LibraryImage
                    :src="review.image"
                    :alt="vars.titleText"
                    class="tw-w-full tw-cursor-pointer"
                    :h-ratio="4.5"
                    :w-ratio="7"
                    style="border-radius: var(--rounded-sm)"
                  />
                  <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
                    <svg width="64" height="64" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18 0C22.7739 0 27.3523 1.89642 30.7279 5.27208C34.1036 8.64773 36 13.2261 36 18C36 22.7739 34.1036 27.3523 30.7279 30.7279C27.3523 34.1036 22.7739 36 18 36C13.2261 36 8.64773 34.1036 5.27208 30.7279C1.89642 27.3523 0 22.7739 0 18C0 13.2261 1.89642 8.64773 5.27208 5.27208C8.64773 1.89642 13.2261 0 18 0ZM3.375 18C3.375 21.8788 4.91584 25.5987 7.65856 28.3414C10.4013 31.0842 14.1212 32.625 18 32.625C21.8788 32.625 25.5987 31.0842 28.3414 28.3414C31.0842 25.5987 32.625 21.8788 32.625 18C32.625 14.1212 31.0842 10.4013 28.3414 7.65856C25.5987 4.91584 21.8788 3.375 18 3.375C14.1212 3.375 10.4013 4.91584 7.65856 7.65856C4.91584 10.4013 3.375 14.1212 3.375 18ZM14.3528 11.7608L23.9467 17.5185C24.0297 17.5686 24.0982 17.6392 24.1458 17.7236C24.1934 17.8079 24.2185 17.9031 24.2185 18C24.2185 18.0969 24.1934 18.1921 24.1458 18.2764C24.0982 18.3608 24.0297 18.4314 23.9467 18.4815L14.3528 24.2393C14.2674 24.2906 14.17 24.3185 14.0704 24.3199C13.9708 24.3213 13.8727 24.2962 13.7859 24.2472C13.6992 24.1983 13.627 24.1271 13.5768 24.0411C13.5266 23.9551 13.5001 23.8573 13.5 23.7577V12.2445C13.4997 12.1447 13.5259 12.0466 13.5759 11.9603C13.626 11.874 13.6982 11.8026 13.7849 11.7533C13.8717 11.7041 13.9701 11.6788 14.0699 11.6801C14.1696 11.6814 14.2673 11.7093 14.3528 11.7608Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>

                <div
                  v-if="!review.image && review.video"
                  class="tw-relative tw-w-full"
                  @click="(isVisible = true), (currentVideoUrl = review.video)"
                >
                  <library-iframe :src="review.video" />
                </div>
                <div class="tw-flex tw-h-full tw-flex-col tw-gap-4">
                  <div class="tw-flex tw-flex-col tw-gap-1">
                    <div class="tw-mt-4 tw-flex tw-items-center tw-gap-2">
                      <svg
                        v-for="n in review.rating"
                        :key="`singletestimonials-star-${n}`"
                        width="32"
                        height="32"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                          fill="var(--c-primary)"
                        />
                      </svg>
                    </div>
                    <h4 class="tw-mt-2">{{ review.title }}</h4>
                  </div>
                  <div class="tw-flex tw-max-h-60 tw-flex-col tw-gap-2 tw-overflow-y-auto xl:tw-max-h-80">
                    <p>{{ review.descriptions }}</p>
                  </div>
                  <p class="n-semibold tw-mt-auto">{{ review.author }}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </MotionGroup>
      <div>
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v4"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>

  <!-- Variant 2 -->
  <section
    v-if="variant === variants.v2"
    class="n-section-primary tw-overflow-hidden"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <LibraryPopupsVideoPopup :videoUrl="currentVideoUrl" v-model:is-visible="isVisible" />

    <div
      class="tw-relative tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-x-32 tw-gap-y-2 lg:tw-max-w-none lg:tw-flex-row"
    >
      <div
        class="tw-mt-14 tw-flex tw-w-full tw-flex-col tw-items-center tw-self-center tw-text-center lg:tw-min-w-[444px] lg:tw-items-start lg:tw-self-start lg:tw-text-start"
      >
        <h2>{{ vars.titleText }}</h2>
        <div class="tw-hidden lg:tw-block">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v5"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
      <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="800">
        <div class="tw-w-full">
          <Swiper
            :modules="[SwiperPagination]"
            @slideChange="onSlideChange"
            :pagination="false"
            :space-between="30"
            effect="fade"
            :clickable="true"
            :breakpoints="{
              320: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 2,
              },
            }"
            @swiper="onSwiper"
            class="tw-w-full xl:tw-w-[130%]"
          >
            <SwiperSlide
              v-for="(review, index) in reviews"
              :key="`singletestimonials-review-${index}`"
              class="!tw-h-auto tw-px-2"
            >
              <div
                class="tw-flex tw-h-full tw-w-full tw-flex-col tw-py-8 tw-duration-300 hover:tw-scale-[0.99]"
                style="border-radius: var(--rounded)"
              >
                <div class="lg:tw-row tw-flex tw-h-full tw-flex-col tw-items-center tw-gap-5 tw-text-start">
                  <LibraryImage
                    v-if="review.image && !review.video"
                    :src="review.image"
                    :alt="vars.titleText"
                    class="tw-w-full"
                    :h-ratio="4.5"
                    :w-ratio="7"
                    style="border-radius: var(--rounded)"
                  />
                  <div
                    v-if="review.image && review.video"
                    class="tw-relative tw-w-full"
                    @click="(isVisible = true), (currentVideoUrl = review.video)"
                  >
                    <LibraryImage
                      :src="review.image"
                      :alt="vars.titleText"
                      class="tw-w-full tw-cursor-pointer"
                      :h-ratio="4.5"
                      :w-ratio="7"
                      style="border-radius: var(--rounded)"
                    />
                    <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
                      <svg width="64" height="64" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M18 0C22.7739 0 27.3523 1.89642 30.7279 5.27208C34.1036 8.64773 36 13.2261 36 18C36 22.7739 34.1036 27.3523 30.7279 30.7279C27.3523 34.1036 22.7739 36 18 36C13.2261 36 8.64773 34.1036 5.27208 30.7279C1.89642 27.3523 0 22.7739 0 18C0 13.2261 1.89642 8.64773 5.27208 5.27208C8.64773 1.89642 13.2261 0 18 0ZM3.375 18C3.375 21.8788 4.91584 25.5987 7.65856 28.3414C10.4013 31.0842 14.1212 32.625 18 32.625C21.8788 32.625 25.5987 31.0842 28.3414 28.3414C31.0842 25.5987 32.625 21.8788 32.625 18C32.625 14.1212 31.0842 10.4013 28.3414 7.65856C25.5987 4.91584 21.8788 3.375 18 3.375C14.1212 3.375 10.4013 4.91584 7.65856 7.65856C4.91584 10.4013 3.375 14.1212 3.375 18ZM14.3528 11.7608L23.9467 17.5185C24.0297 17.5686 24.0982 17.6392 24.1458 17.7236C24.1934 17.8079 24.2185 17.9031 24.2185 18C24.2185 18.0969 24.1934 18.1921 24.1458 18.2764C24.0982 18.3608 24.0297 18.4314 23.9467 18.4815L14.3528 24.2393C14.2674 24.2906 14.17 24.3185 14.0704 24.3199C13.9708 24.3213 13.8727 24.2962 13.7859 24.2472C13.6992 24.1983 13.627 24.1271 13.5768 24.0411C13.5266 23.9551 13.5001 23.8573 13.5 23.7577V12.2445C13.4997 12.1447 13.5259 12.0466 13.5759 11.9603C13.626 11.874 13.6982 11.8026 13.7849 11.7533C13.8717 11.7041 13.9701 11.6788 14.0699 11.6801C14.1696 11.6814 14.2673 11.7093 14.3528 11.7608Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="tw-flex tw-h-full tw-flex-col tw-gap-4">
                    <div class="tw-flex tw-flex-col tw-gap-1">
                      <div class="tw-mt-4 tw-flex tw-items-center tw-gap-1">
                        <svg
                          v-for="n in review.rating"
                          :key="`singletestimonials-star-${n}`"
                          width="28"
                          height="28"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                            fill="var(--c-primary)"
                          />
                        </svg>
                      </div>
                      <h6 class="tw-mt-2">{{ review.title }}</h6>
                    </div>
                    <div class="tw-flex tw-max-h-60 tw-flex-col tw-gap-2 tw-overflow-y-auto xl:tw-max-h-80">
                      <p>{{ review.descriptions }}</p>
                    </div>
                    <span class="tw-mt-auto tw-text-[var(--c-tertiary)]">{{ review.author }}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </MotionGroup>
      <div class="lg:tw-hidden">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v5"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoTestimonial',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      currentVideoUrl: '',
      isVisible: false,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange() {
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
  mounted() {
    this.onSlideChange();
  },
  computed: {
    reviews() {
      return this.groupedVariables.reviews;
    },
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: var(--c-secondary);
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-primary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
</style>
