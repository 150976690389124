<template>
  <!-- Variant 1 -->
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div
      class="tw-mb-8 tw-flex tw-flex-col tw-self-start tw-text-start md:tw-self-center md:tw-text-center lg:tw-mb-12"
    >
      <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
      <h2>{{ vars.titleText }}</h2>
    </div>

    <!-- Swiper for Mobile -->
    <div class="tw-relative tw-w-full !tw-text-white lg:tw-hidden">
      <Swiper
        :modules="[SwiperPagination]"
        :pagination="false"
        :slides-per-view="1"
        :space-between="20"
        effect="fade"
        @swiper="onSwiper"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }"
        class="tw-w-full"
      >
        <SwiperSlide v-for="(member, index) in members" :key="index" @click="toggleTeamMemberDetailPopup(member, true)">
          <div
            class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500 md:hover:tw-scale-105"
          >
            <LibraryImage :w-ratio="14" :h-ratio="9" :src="member.member_photo" style="border-radius: var(--rounded)" />
            <div
              class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-50"
              style="border-radius: var(--rounded)"
            ></div>
            <div
              class="agent-info-v1 tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:tw-translate-y-0 group-hover:tw-opacity-100"
            >
              <div class="tw-flex tw-flex-col">
                <h6>{{ member.member_name }}</h6>
                <p class="n-semibold">{{ member.member_role }}</p>
              </div>
              <button class="n-link" @click="toggleTeamMemberDetailPopup(member, true)">VIEW MORE DETAILS</button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="lg:!tw-hidden">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v4"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>

    <!-- Flex for Desktop -->
    <div class="tw-hidden tw-w-full tw-justify-center tw-gap-4 !tw-text-white lg:tw-flex lg:tw-flex-wrap">
      <div
        v-for="(member, index) in members"
        :key="index"
        class="no-hover shine-effect tw-group tw-relative tw-basis-[32%] tw-overflow-hidden tw-transition-all tw-duration-500 md:hover:tw-scale-105"
      >
        <LibraryImage :w-ratio="14" :h-ratio="9" :src="member.member_photo" style="border-radius: var(--rounded)" />
        <div
          class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-50"
          style="border-radius: var(--rounded)"
        ></div>
        <div
          class="agent-info-v1 tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:tw-translate-y-0 group-hover:tw-opacity-100"
        >
          <div class="tw-flex tw-flex-col">
            <h6>{{ member.member_name }}</h6>
            <p class="n-semibold">{{ member.member_role }}</p>
          </div>
          <button class="n-link" @click="toggleTeamMemberDetailPopup(member, true)">VIEW MORE DETAILS</button>
        </div>
      </div>
    </div>

    <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="400">
      <popup v-if="selectedMember && popupConfig.isActive" :config="popupConfig" custom-width="90%">
        <template #content>
          <div class="tw-grid tw-h-full tw-w-full tw-grid-cols-12 tw-gap-y-4 tw-py-4 lg:tw-gap-x-6">
            <div class="tw-col-span-12 tw-flex tw-w-full tw-flex-col tw-justify-center lg:tw-col-span-7">
              <h2>{{ selectedMember.member_name }}</h2>
              <p class="n-semibold tw-mt-2">{{ selectedMember.member_role }}</p>
              <div class="tw-py-8">
                <div v-if="selectedMember.member_email" class="tw-flex tw-items-center tw-gap-1 tw-pb-3">
                  <nuxt-link :to="`mailto:${selectedMember.member_email}`" class="n-link">
                    <p class="n-semibold">{{ selectedMember.member_email }}</p>
                  </nuxt-link>
                </div>
                <div v-if="selectedMember.member_phone" class="tw-flex tw-items-center tw-gap-1">
                  <nuxt-link :to="`tel:${selectedMember.member_phone}`" class="n-link">
                    <p class="n-semibold">{{ selectedMember.member_phone }}</p>
                  </nuxt-link>
                </div>
              </div>
              <div class="tw-flex tw-flex-col tw-gap-3" v-html="selectedMember.popup_descriptions"></div>
              <nuxt-link
                :external="true"
                :class="`n-${selectedMember.popup_button_type} n-btn tw-mt-12 tw-max-w-fit`"
                :to="selectedMember.popup_button_link"
                v-if="selectedMember.popup_button_link"
              >
                <div class="n-btn-container">
                  <div class="n-btn-group">
                    <div class="n-btn-t1">{{ selectedMember.popup_button_label }}</div>
                    <div class="n-btn-t2">{{ selectedMember.popup_button_label }}</div>
                  </div>
                </div>
              </nuxt-link>
            </div>
            <div
              class="tw-order-first tw-col-span-12 tw-flex tw-items-center tw-justify-center md:tw-col-span-8 lg:tw-order-none lg:tw-col-span-5"
            >
              <div>
                <div style="position: relative; height: 100%; width: 100%">
                  <NuxtImg
                    v-if="selectedMember.popup_video_cover"
                    :src="selectedMember.popup_video_cover"
                    :alt="selectedMember.member_name"
                    class="tw-w-full tw-rounded"
                  />
                  <div class="tw-absolute tw-top-0 tw-z-10 tw-h-full tw-w-full" @click="isVisible = true">
                    <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
                      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M32 0C40.4869 0 48.6263 3.37142 54.6274 9.37258C60.6286 15.3737 64 23.5131 64 32C64 40.4869 60.6286 48.6263 54.6274 54.6274C48.6263 60.6286 40.4869 64 32 64C23.5131 64 15.3737 60.6286 9.37258 54.6274C3.37142 48.6263 0 40.4869 0 32C0 23.5131 3.37142 15.3737 9.37258 9.37258C15.3737 3.37142 23.5131 0 32 0ZM6 32C6 38.8956 8.73928 45.5088 13.6152 50.3848C18.4912 55.2607 25.1044 58 32 58C38.8956 58 45.5088 55.2607 50.3848 50.3848C55.2607 45.5088 58 38.8956 58 32C58 25.1044 55.2607 18.4912 50.3848 13.6152C45.5088 8.73928 38.8956 6 32 6C25.1044 6 18.4912 8.73928 13.6152 13.6152C8.73928 18.4912 6 25.1044 6 32ZM25.516 20.908L42.572 31.144C42.7194 31.233 42.8413 31.3586 42.9259 31.5086C43.0106 31.6585 43.055 31.8278 43.055 32C43.055 32.1722 43.0106 32.3415 42.9259 32.4914C42.8413 32.6414 42.7194 32.767 42.572 32.856L25.516 43.092C25.3643 43.1834 25.1911 43.2328 25.0141 43.2353C24.837 43.2378 24.6625 43.1932 24.5083 43.1062C24.3541 43.0191 24.2258 42.8927 24.1365 42.7398C24.0472 42.5869 24.0001 42.4131 24 42.236V21.768C23.9994 21.5906 24.046 21.4162 24.135 21.2628C24.224 21.1093 24.3523 20.9823 24.5066 20.8948C24.6609 20.8073 24.8357 20.7624 25.0131 20.7647C25.1905 20.767 25.364 20.8165 25.516 20.908Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </MotionGroup>

    <library-popups-video-popup
      v-if="selectedMember?.popup_video"
      :videoUrl="selectedMember?.popup_video"
      v-model:is-visible="isVisible"
    />
  </section>

  <!-- Variant 2 -->
  <section
    v-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-mb-1 tw-flex tw-flex-col tw-self-center tw-text-center lg:tw-mb-12">
      <h2>{{ vars.titleText }}</h2>
    </div>

    <!-- Swiper for Mobile -->
    <div class="tw-relative tw-w-full !tw-text-white lg:tw-hidden">
      <Swiper
        :modules="[SwiperPagination]"
        :pagination="false"
        :slides-per-view="1"
        :space-between="20"
        effect="fade"
        @swiper="onSwiper"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }"
        class="tw-w-full"
      >
        <SwiperSlide v-for="(member, index) in members" :key="index">
          <nuxt-link class="n-link" :to="member.member_link">
            <div
              class="no-hover shine-effect tw-group tw-relative tw-basis-1/3 tw-overflow-hidden tw-transition-all tw-duration-500"
            >
              <LibraryImage
                :w-ratio="9"
                :h-ratio="12"
                :src="member.member_photo"
                style="border-radius: var(--rounded)"
              />
              <div
                class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-20"
                style="border-radius: var(--rounded)"
              ></div>
              <div
                class="agent-info-v2 tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:-tw-translate-y-16 group-hover:tw-opacity-100"
              >
                <div class="tw-flex tw-flex-col tw-gap-6">
                  <h6>{{ member.member_name }}</h6>
                  <p class="n-semibold">{{ member.member_role }}</p>
                </div>
              </div>
            </div>
          </nuxt-link>
        </SwiperSlide>
      </Swiper>
      <div class="lg:!tw-hidden">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v5"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>

    <!-- Flex for Desktop -->
    <div class="tw-hidden tw-w-full tw-justify-center tw-gap-8 !tw-text-white lg:tw-flex lg:tw-flex-wrap">
      <nuxt-link v-for="(member, index) in members" :key="index" class="n-link tw-basis-[31%]" :to="member.member_link">
        <div class="no-hover shine-effect tw-group tw-relative tw-overflow-hidden tw-transition-all tw-duration-500">
          <LibraryImage :w-ratio="9" :h-ratio="12" :src="member.member_photo" style="border-radius: var(--rounded)" />
          <div
            class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-20"
            style="border-radius: var(--rounded)"
          ></div>
          <div
            class="agent-info-v2 tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:-tw-translate-y-16 group-hover:tw-opacity-100"
          >
            <div class="tw-flex tw-flex-col tw-gap-6">
              <h6>{{ member.member_name }}</h6>
              <p class="n-semibold">{{ member.member_role }}</p>
            </div>
          </div>
        </div>
      </nuxt-link>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

import Popup from '~/components/common/popup/Popup.vue';
export default defineNuxtComponent({
  components: { Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      selectedMember: null,
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      popupConfig: {
        isActive: false,
      },
      isVisible: false,
    };
  },

  mounted() {
    this.onSlideChange();
  },

  computed: {
    members() {
      return this.groupedVariables.members;
    },
  },

  methods: {
    toggleTeamMemberDetailPopup(member = null, value = false) {
      if (member.popup_title) {
        this.selectedMember = member;
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>

<style scoped>
@media (hover: none) {
  .no-hover .shine-effect {
    filter: none;
  }

  .no-hover .shine {
    opacity: 30%;
  }

  .no-hover .agent-info-v2 {
    opacity: 100%;
    pointer-events: auto;
    transform: translateY(-4rem);
  }
  .no-hover .agent-info-v1 {
    opacity: 100%;
    pointer-events: auto;
    transform: translateY(0px);
  }
}
</style>
